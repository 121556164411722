body{
  a{
    &:hover{
      text-decoration: none;
    }
  }

  .title {
    text-transform: uppercase;
    font-weight: 800;
  }
  .title-soft {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .subtitle{
    margin: 0;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 400;
  }

  .no-picture {
    padding: 15px;
  }
}