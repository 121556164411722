section[data-section="marca-detail"] {

  .sidebar {
    ul {
      margin: 0;
      padding: 0;

      li {
        text-transform: uppercase;
        letter-spacing: 1px;
        list-style: none;
        //margin-left: 10px;

        a {
          border-bottom: 1px solid lightgrey;
          display: block;
          padding: 4px 0;
          font-weight: 600;
          color: grey;
          transition: all .3s ease;

          &:hover {
            border-bottom: 1px solid grey;
            color: grey;
          }
        }
      }
    }
  }

  .subtitle {
    font-size: 1.3rem;
    color: #5e5e5e !important;
  }

  h3.title-soft {
    font-size: 1.9rem;
    font-weight: 800;
  }

  h4.title-soft {
    font-weight: 800;
    font-size: 1.4rem;
  }

  .item-producto {
    min-height: 430px;
    margin-bottom: 10px;
    background: white;
    text-align: center;
    box-shadow: 0 0 2px grey;
    border-radius: 3px;
    transition: all .3s ease;

    &:hover {
      box-shadow: 0 0 12px grey;
    }

    figure {
      min-height: 305px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin: 0 auto;
        max-height: 280px;
      }
    }

    .data {
      padding: 5px;

      .btn-link {
        margin-top: 5px;
        //border: 1px solid lightgrey;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1rem;
        transition: all .3s ease;
        color: black;

        &:hover {
          text-decoration: none;
          border: 1px solid lightgrey;
        }
      }

      h3 {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 1.8rem;
        margin: 0 0 10px;
      }

    }

  }
}