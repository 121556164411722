section[data-section='producto'] {
  .send-consul {
    .form-control{
      margin-bottom: 10px;
    }
  }

  .gal-product-container {
    position: relative;

    button.next, button.prev {
      position: absolute;
      background: rgba(black, .5);
      color: white;
      border: none;
      padding: 8px 5px 8px 5px;
      font-size: 1.1rem;
      top: 50%;
      transform: translateY(-50%);
      z-index: 888;
    }

    button.prev {
      left: 0;
    }

    button.next {
      right: 0;
    }


    .gal-product {

      .slick-slide {
        display: flex !important;
        align-items: center !important;
        align-content: center;
        height: 500px;

        img {
          margin: 0 auto;
          max-width: 100%;
          max-height: 480px;
        }
      }
    }
  }
}